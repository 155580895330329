export const FOOTER_IS_SHOWING = false

export default (state, action) => {
  switch (action.type) {
    case FOOTER_IS_SHOWING:
      return { isShowing: action.isShowing }
    default:
      return { isShowing: false }
  }
}
