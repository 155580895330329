import React, { useState } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"
import CentreContainer from "@/components/Container"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Drawer from "@/components/Drawer"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Slide from "@material-ui/core/Slide"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { trackCustomEvent } from "@/utils/ga"

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const useStyles = makeStyles(theme => ({
  appBar: {
    background: props =>
      props.enableBackground ? theme.palette.background.paper : "transparent",
    borderBottom: props =>
      props.enableBackground
        ? `1px ${theme.palette.primary.main} solid`
        : "none",
    color: theme.palette.primary.main,
    boxShadow: "none",
  },
  iconButton: {
    marginRight: theme.spacing(1.5) * -1,
  },
  menuButton: {
    fill: props =>
      props.enableBackground
        ? theme.palette.primary.contrastText
        : theme.palette.background.paper,
  },
  toolBar: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: 0,
    padding: 0,
  },
}))

export default function Header(props) {
  const { enableBackground, localePath } = props

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isStartScroll, setIsStartScroll] = useState(false)

  const shouldBackgroundToggleOn = enableBackground || isStartScroll

  useScrollPosition(({ currPos }) => {
    setIsStartScroll(currPos.y < -54)
  })

  const classes = useStyles({
    enableBackground: shouldBackgroundToggleOn,
  })

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar}>
          <CentreContainer>
            <Toolbar className={classes.toolBar}>
              <IconButton
                className={classes.iconButton}
                aria-label="menu"
                onClick={() => {
                  trackCustomEvent("Open", {
                    event_category: "Hamburger Menu",
                  })
                  setDrawerOpen(!drawerOpen)
                }}
              >
                <MenuIcon className={classes.menuButton} />
              </IconButton>
            </Toolbar>
          </CentreContainer>
        </AppBar>
      </HideOnScroll>
      <Drawer
        isOpen={drawerOpen}
        closeAction={() => {
          trackCustomEvent("Close", {
            event_category: "Hamburger Menu",
          })
          setDrawerOpen(false)
        }}
        localePath={localePath}
      />
    </>
  )
}
