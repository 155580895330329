import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"
import getRoute from "@/utils/routes"

const Link = styled.span`
  ${({ theme }) => `
  a, a:visited {
    color: ${theme.palette.primary.contrastText};
    text-decoration: none;
    border-bottom: 3px solid ${theme.palette.primary.main};
    cursor: pointer;
  }
  `}
`

const UnstyledLink = styled.span`
  ${({ theme }) => `
  a, a:visited {
    color: ${theme.palette.primary.contrastText};
    text-decoration: none;
    cursor: pointer;
  }
  `}
`

export default function FormattedLink({ to, children }) {
  return (
    <Link>
      <GatsbyLink to={getRoute(to)}>{children}</GatsbyLink>
    </Link>
  )
}

export function ExternalLink({ to, children, style = "default" }) {
  if (style === "plain") {
    return (
      <UnstyledLink>
        <a href={to} target="_blank" rel="noreferrer">
          {children}
        </a>
      </UnstyledLink>
    )
  }

  return (
    <Link>
      <a href={to} target="_blank" rel="noreferrer">
        {children}
      </a>
    </Link>
  )
}

export function PlainLink({ to, children }) {
  return (
    <UnstyledLink>
      <GatsbyLink to={getRoute(to)}>{children}</GatsbyLink>
    </UnstyledLink>
  )
}
