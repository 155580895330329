/**
 * No import syntax as this is needed for gatsby-node.js (dont want to setup babel lol)
 */

const removePathTrailingSlash = _path =>
  _path === "/" ? _path : _path.replace(/\/$/, "")

const searchRegExp = /(\s|#)/g
const replaceWith = "-"

const getPath = (lang, path) => {
  const outputPath = removePathTrailingSlash(
    `${lang === "zh" ? "" : `/${lang}`}${path}`
  ).toLowerCase()
  return outputPath.replace(searchRegExp, replaceWith)
}

module.exports = {
  getPath,
  removePathTrailingSlash,
}
