import React from "react"
import routeReducer from "@/reducers/route"
import footerReducer from "@/reducers/footer"
import stickyCardReducer from "@/reducers/stickyCard"

export const routeInitialState = {
  path: "/",
  fullPath: "/",
}

export const footerInitialState = {
  isShowing: false,
}

export const stickyCardInitialState = {
  selectedDefendant: {
    person: {
      id: "8009baa9-05f1-4066-a566-707a9d7df60b",
      initial_zh: "冼",
      initial_en: null,
      hide_name: true,
      gender: "male",
      image_url: null,
      case_person: [
        {
          arrest_age: 22,
          occupation: { type_zh: "救生員", type_en: "救生員" },
          status: { id: 3, type_zh: "服刑中", type_en: "服刑中" },
        },
        {
          arrest_age: 22,
          occupation: { type_zh: "救生員", type_en: "救生員" },
          status: { id: 3, type_zh: "服刑中", type_en: "服刑中" },
        },
      ],
    },
    status: {
      id: 3,
      type_zh: "服刑中",
      type_en: "服刑中",
      fill: "#570606",
      contrast: "#FFFFFF",
    },
    id: "8009baa9-05f1-4066-a566-707a9d7df60b",
    context: "status",
  },
  shown: false,
}

const ContextStore = React.createContext({
  route: routeInitialState,
  footer: footerInitialState,
  stickyCard: stickyCardInitialState,
})

export const ContextStoreProvider = props => {
  const { initialStore = {} } = props

  const [routeState, routeDispatch] = React.useReducer(routeReducer, {
    ...routeInitialState,
    ...initialStore.route,
  })

  const [footerState, footerDispatch] = React.useReducer(footerReducer, {
    ...footerInitialState,
    ...initialStore.route,
  })

  const [stickyCardState, stickyCardDispatch] = React.useReducer(
    stickyCardReducer,
    {
      ...stickyCardInitialState,
      ...initialStore.route,
    }
  )

  return (
    <ContextStore.Provider
      value={{
        route: {
          state: routeState,
          dispatch: routeDispatch,
        },
        footer: {
          state: footerState,
          dispatch: footerDispatch,
        },
        stickyCard: {
          state: stickyCardState,
          dispatch: stickyCardDispatch,
        },
      }}
      {...props}
    />
  )
}

export default ContextStore
