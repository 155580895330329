export const get = (obj, path, defaultValue = undefined) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export const getRandomNItemsFromArray = (arr, n) => {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len)
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available")
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export const parseLinks = ({ rawLinks }) => {
  const links = []
  for (const [i, r] of rawLinks.split("\n").entries()) {
    if (i % 2 === 0) {
      links.push({
        text: r,
      })
    } else {
      links[Math.floor(i / 2)].url = r
    }
  }
  return links
}

export const getLegend = (oldLegend, clickedIndex) => {
  const temp = Object.assign(oldLegend, {})
  const selectedStatus = temp.filter(t => t.selected)

  // User clicks one item from all
  if (selectedStatus.length === temp.length) {
    for (const [i, s] of temp.entries()) {
      s.highlight = i === clickedIndex ? true : false
      s.selected = i === clickedIndex ? true : false
    }
    return [...temp]
  }

  // User clicks another item
  const selectedIndex = temp.findIndex(t => t.highlight)
  if (clickedIndex === selectedIndex) {
    return [
      ...temp.map(t => ({
        ...t,
        highlight: false,
        selected: true,
      })),
    ]
  }

  // User clicks the same item -> Restore
  return [
    ...temp.map((t, i) => ({
      ...t,
      highlight: i === clickedIndex ? true : false,
      selected: i === clickedIndex ? true : false,
    })),
  ]
}

export const setCookie = (name, value, expired) => {
  if (typeof document !== `undefined`) {
    let now
    if (expired) {
      now = new Date()
      const time = now.getTime()
      now.setTime(time + expired)
    }
    document.cookie = `${name}=${value};${
      expired ? `expires=${now.toUTCString()}` : ``
    }`
  }
}

export const getCookie = name =>
  (typeof document !== `undefined` &&
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop()) ||
  ""
