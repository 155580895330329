import React, { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import CentreContainer from "@/components/Container"
import ContextStore from "@/contextStore"
import { FOOTER_IS_SHOWING } from "@/reducers/footer"
import { useTranslation } from "react-i18next"
import { ExternalLink } from "@/components/Link"
import { InView } from "react-intersection-observer"

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(8),
  },
  footerRow: {
    display: "grid",
    gridTemplateColumns: `1fr`,
    textAlign: "center",
    [theme.breakpoints.up("viewport7")]: {
      maxWidth: "100%",
      textAlign: "left",
    },
  },
  iconRow: {
    display: "flex",
    width: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "space-between",
    [theme.breakpoints.up("viewport7")]: {
      marginLeft: 0,
      justifyContent: "flex-start",
    },
  },
  iconWrapper: {
    cursor: "pointer",
    [theme.breakpoints.up("viewport7")]: {
      marginRight: theme.spacing(3),
    },
  },
  importantLinksRow: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    gridGap: theme.spacing(4),
    width: "100%",
    justifyContent: "space-evenly",
    "& a": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("viewport7")]: {
      marginLeft: 0,
      justifyContent: "flex-start",
      "& a": {
        marginRight: theme.spacing(3),
      },
    },
  },
}))

const importantLinks = []

export default function Footer() {
  const classes = useStyles()

  const {
    footer: { dispatch },
  } = useContext(ContextStore)

  const { t } = useTranslation()

  const onScreen = inView => {
    dispatch({
      type: FOOTER_IS_SHOWING,
      isShowing: inView,
    })
  }

  return (
    <InView as="footer" className={classes.root} onChange={onScreen}>
      <CentreContainer>
        <div className={classes.footerRow}></div>
        <div className={classes.footerRow}>
          <div className={classes.importantLinksRow}>
            {importantLinks.map(link => {
              return (
                <ExternalLink
                  key={link}
                  to={t(`site.${link}_url`)}
                  style="plain"
                >
                  {t(`site.${link}`)}
                </ExternalLink>
              )
            })}
          </div>
        </div>
      </CentreContainer>
    </InView>
  )
}
