import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tab from "@/components/Tab"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import { withLanguage } from "@/utils/i18n"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { PlainLink } from "@/components/Link"
import { getLocalizedPath } from "@/utils/i18n"
import { ENABLE_ENGLISH } from "@/config/default.json"
import LanguageSwitcher from "@/components/LanguageSwitcher"
import { trackCustomEvent } from "@/utils/ga"

const useStyles = makeStyles(
  theme => ({
    drawer: {
      "& .MuiPaper-root": {
        width: "100%",
        background: theme.palette.background.default,
        [theme.breakpoints.up("viewport7")]: {
          width: "500px",
        },
      },
    },
    root: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    topSection: {
      marginTop: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    main: {
      marginTop: theme.spacing(2),
    },
    iconButton: {
      marginRight: theme.spacing(1.5) * -1,
    },
    closeButton: {
      fill: theme.palette.primary.contrastText,
    },
    tabWrapper: {
      marginTop: theme.spacing(4),
    },
    tabInnerWrapper: {
      paddingBottom: theme.spacing(8),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    date: {
      lineHeight: "0.8rem",
    },
    linkItem: {
      marginTop: theme.spacing(3),
    },
    link: {
      cursor: "pointer",
      display: "inline",
      "&:hover": {
        textDecoration: "none",
        borderBottom: `3px solid ${theme.palette.primary.main}`,
        transition: `0.3s`,
      },
    },
  }),
  { name: "MuiButton" }
)

export default function DrawerComponent({ isOpen, closeAction, localePath }) {
  const {
    cms: { charges, incidents },
  } = useStaticQuery(DrawerQuery)

  const classes = useStyles()
  const { i18n, t } = useTranslation()

  const tabs = [
    {
      title: t("title.incident"),
      content: (
        <div className={classes.tabInnerWrapper}>
          {incidents.map(incident => {
            return (
              <div key={incident.id} className={classes.linkItem}>
                <PlainLink
                  to={{
                    page: "template-incident",
                    lang: i18n.language,
                    obj: incident,
                  }}
                >
                  <div className={classes.date}>{incident.date}</div>
                  <Typography variant="h4" className={classes.link}>
                    {withLanguage(i18n, incident, "name")}
                  </Typography>
                </PlainLink>
              </div>
            )
          })}
        </div>
      ),
    },
    {
      title: t("title.charge"),
      content: (
        <div className={classes.tabInnerWrapper}>
          {charges.map(charge => {
            return (
              <div key={charge.id} className={classes.linkItem}>
                <PlainLink
                  to={{
                    page: "template-charge",
                    lang: i18n.language,
                    obj: charge,
                  }}
                >
                  <Typography variant="h4" className={classes.link}>
                    {withLanguage(i18n, charge, "name")}
                  </Typography>
                </PlainLink>
              </div>
            )
          })}
        </div>
      ),
    },
  ]

  const handleTabId = id => {
    trackCustomEvent("Click", {
      event_category: "Tab",
      event_label: `Hamburger - ${tabs[id].title}`,
    })
  }

  return (
    <Drawer
      className={classes.drawer}
      onClose={() => closeAction()}
      open={isOpen}
      anchor="right"
    >
      <div className={classes.root}>
        <div className={classes.topSection}>
          <IconButton
            className={classes.iconButton}
            aria-label="close"
            onClick={() => closeAction()}
          >
            <CloseIcon className={classes.closeButton} />
          </IconButton>
        </div>
        <div className={classes.main}>
          {ENABLE_ENGLISH && <LanguageSwitcher localePath={localePath} />}
          <span onClick={() => navigate(getLocalizedPath(i18n, "/"))}>
            <Typography variant="h3" className={classes.link}>
              {t("site.title")}
            </Typography>
          </span>
        </div>
      </div>
      <div className={classes.tabWrapper}>
        <Tab tabs={tabs} passActiveId={handleTabId} />
      </div>
    </Drawer>
  )
}

export const DrawerQuery = graphql`
  query {
    cms {
      charges(order_by: { sort_order: asc }) {
        ...ChargeBasicInfo
      }
      incidents(order_by: { date: asc }) {
        ...IncidentBasicInfo
        desc_zh
        desc_en
      }
    }
  }
`
