/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { ContextStoreProvider } from "@/contextStore"
import { useTranslation } from "react-i18next"
import theme from "@/theme"
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "styled-components"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: props => (props.fullWidthImage ? 0 : 56),
    flexGrow: 1,
  },
})

const fullWidthPageRegex = new RegExp(["incident", "charge"].join("|"), "gi")

const Layout = ({ localePath, children, initialStore }) => {
  let fullWidthImage = false

  if (
    localePath &&
    (localePath === "/" ||
      (typeof localePath !== "string" &&
        localePath.zh.match(fullWidthPageRegex)))
  ) {
    fullWidthImage = true
  }
  const classes = useStyles({
    fullWidthImage,
  })

  const { t } = useTranslation()

  return (
    <ContextStoreProvider initialStore={initialStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
          <Header
            title={t("site.title_dev")}
            to={t("site.main_site_url")}
            localePath={localePath}
            enableBackground={!fullWidthImage}
          />
          <main className={classes.main}>{children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    </ContextStoreProvider>
  )
}

export default Layout
