/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

function SEO({ description, title }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { t } = useTranslation()

  const { siteUrl } = site.siteMetadata

  const seo = {
    url: `${siteUrl}${pathname}`,
    title: title ? `${title} | ${t("site.title")}` : t("site.title"),
    description: description || t("site.description"),
  }

  return (
    <Helmet title={seo.title}>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Noto+Serif+TC:wght@400;700;900&display=swap;subset"
      />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Noto+Serif+TC:wght@400;700;900&display=swap;subset"
        media="print"
        onLoad="this.media='all'"
      />
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`

export default SEO
