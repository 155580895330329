import React from "react"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import { removePathTrailingSlash } from "@/utils/urlHelper"

function LanguageSwitcher({ localePath }) {
  const changeLanguage = lng => {
    if (typeof localePath === "string") {
      let fullPath = localePath
      if (lng === "en" && !localePath.includes("/en")) {
        fullPath = removePathTrailingSlash(fullPath.replace("/", "/en/"))
        navigate(fullPath, { replace: true })
      } else if (lng === "zh" && localePath.includes("/en")) {
        fullPath = removePathTrailingSlash(fullPath.replace("/en", "/"))
        navigate(fullPath, { replace: true })
      }
    }
    // Template pages
    navigate(localePath[lng])
  }

  const { t, i18n } = useTranslation()
  return (
    <>
      {i18n.language === "zh" ? (
        <span onClick={() => changeLanguage("en")}>{t("common.english")}</span>
      ) : (
        <span onClick={() => changeLanguage("zh")}>{t("common.chinese")}</span>
      )}
    </>
  )
}

export default LanguageSwitcher
