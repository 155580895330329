import React from "react"
import styled from "styled-components"

const Container = styled.div`
  ${({ theme, padding }) => `
  padding: 0 ${theme.spacing(padding)}px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${theme.breakpoints.up("viewport7")} {
        width: 768px;
    }

  ${theme.breakpoints.up("viewport9")} {
        width: 840px;
    }
    
  `}
`

export default function CentreContainer({ padding = 2, children }) {
  return <Container padding={padding}>{children}</Container>
}
