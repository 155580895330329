import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import CentreContainer from "@/components/Container"

function TabPanel(props) {
  const { children, value, index, ...other } = props
  if (value !== index) return <></>
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>
        <>{children}</>
      </Box>
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: "none",
    paddingBottom: theme.spacing(2),
  },
  tabPanel: {
    "& .MuiBox-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  indicator: {
    zIndex: 0,
    height: "100%",
    background: "black",
    border: "1px #555555 solid",
    boxShadow: `${theme.spacing(0.5)}px ${theme.spacing(
      0.5
    )}px 0px 0px #b6b6b6`,
    backgroundColor: theme.palette.background.default,
  },
  tabButton: {
    zIndex: 1,
    marginRight: theme.spacing(2),
    "&.Mui-selected": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.contrastText,
      "&:hover": {
        opacity: "50%",
        transition: `0.3s`,
      },
    },
  },
}))

export default function SimpleTabs({ tabs, passActiveId = () => {} }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    passActiveId(newValue)
  }

  const titles = tabs.map(tab => tab.title)
  const contents = tabs.map(tab => tab.content)

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <CentreContainer>
          <Tabs
            classes={{
              indicator: classes.indicator,
            }}
            value={value}
            onChange={handleChange}
          >
            {titles.map((title, i) => (
              <Tab
                className={classes.tabButton}
                key={i}
                label={<Typography variant="h3">{title}</Typography>}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </CentreContainer>
      </AppBar>
      {contents.map((content, i) => (
        <TabPanel className={classes.tabPanel} key={i} value={value} index={i}>
          {content}
        </TabPanel>
      ))}
    </div>
  )
}
