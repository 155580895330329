const { getPath } = require("./urlHelper")

const getRoute = ({ page, lang, obj }) => {
  switch (page) {
    case "template-defendant":
      return getPath(lang, `/defendant/${obj.id}`)
    case "template-incident":
      return getPath(lang, `/incident/${obj[`name_${lang}`] || obj[`name_zh`]}`)
    case "template-charge":
      return getPath(lang, `/charge/${obj[`name_${lang}`] || obj[`name_zh`]}`)
  }
}

module.exports = getRoute
