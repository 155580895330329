import { navigate } from "gatsby"
import getRoute from "@/utils/routes"

export const SET_DEFENDANT = "SET_DEFENDANT"

export default (state, action) => {
  switch (action.type) {
    case SET_DEFENDANT:
      // TODO: clarify this weird behaviour, navigate if mouse over again??
      if (
        state.selectedDefendant &&
        action.selectedDefendant.context === state.selectedDefendant.context &&
        action.selectedDefendant.id === state.selectedDefendant.id
      ) {
        navigate(
          getRoute({
            page: "template-defendant",
            lang: action.i18n.language,
            obj: state.selectedDefendant.person,
          })
        )
      }
      return { selectedDefendant: action.selectedDefendant, shown: true }
    default:
      return { ...state, shown: false }
  }
}
